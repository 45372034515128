<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data') }}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'arl-codes-list'}"
        >
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list') }}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other') }}
      </div>
    </b-alert>

    <template v-if="elementData && serviceBundleList">
      <element-edit-tab
        :element-data="elementData"
        :service-bundle-list="serviceBundleList"
        class="mt-2 pt-75"
      />
    </template>

  </div>
</template>

<script>
import {
  BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import elementStoreModule from '../storeModule'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab,
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-arl-codes',
      RESOURCES_ELEMENT_NAME: 'arl_codes',
      newElement: true,
      serviceBundleList: [],
      elementData: undefined,
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, elementStoreModule)

    this.elementData = await this.fetchElement()
    this.serviceBundleList = await this.fetch_service_bundles()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_service_bundles() {
      return store.dispatch(`${this.ARL_CODES_APP_STORE_MODULE_NAME}/fetch_service_bundles`, { arl_id: this.elementData.arl_id }).then(response => {
        const list = response.data
        list.unshift({ id: null, bundle_name_value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchElement() {
      this.newElement = false
      return store.dispatch(`${this.ARL_CODES_APP_STORE_MODULE_NAME}/fetch_element`, { id: router.currentRoute.params.id })
        .then(response => response.data)
        .catch(error => undefined)
    },
    save(elementData) {
      const method = `${this.ARL_CODES_APP_STORE_MODULE_NAME}/save_element`
      this.newElement = false
      const _self = this
      store
        .dispatch(method, { data: elementData })
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.success`),
            },
          })
          _self.$router.replace({ name: 'arl-codes-list' })
        })
        .catch(error => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.failure`),
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
