<template>
  <b-form @submit.prevent="save">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">Información Principal</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="6">
              <b-form-group label-for="bundle">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.package')
                }}</span>
                <b-form-input
                  id="bundle"
                  v-model="
                    elementData.service_bundle_group.bundle.bundle_name.value
                  "
                  :disabled="true" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-for="name">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.group')
                }}</span>
                <b-form-input
                  id="name"
                  v-model="elementData.service_bundle_group.name"
                  :disabled="true" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-table
                v-if="elementData.services"
                id="refElementsListTable"
                ref="refElementsListTable"
                :empty-text="$t(resourcesElementName + '.search.not_found')"
                :fields="tableColumns"
                :items="elementData.services"
                primary-key="id"
                responsive
                show-empty />
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="8">
                  <date-picker
                    :element-required="true"
                    element-field="authorization_date"
                    :root-element-data="elementData"
                    :element-label="
                      $t(resourcesElementName + '.object.authorization_date')
                    " />
                </b-col>

                <b-col cols="4">
                  <b-form-group label-for="vigence_date">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.vigence_date')
                    }}</span>
                    <b-form-input
                      id="vigence_date"
                      v-model="vigence_date_render"
                      :disabled="true" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-for="arl_code">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl_code') }}</span>
                    <span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(resourcesElementName + '.object.arl_code')"
                    >
                      <b-form-input
                        id="arl_code"
                        v-model="elementData.arl_code"
                        maxlength="20"
                        @input="validateInput"
                      />
                      <small class="text-danger">{{ errors[0] || customError }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-for="authorizated_by">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.authorizated_by')
                    }}</span
                    ><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(resourcesElementName + '.object.authorizated_by')
                      "
                      rules="required">
                      <b-form-input
                        id="authorizated_by"
                        v-model="elementData.authorizated_by"
                        maxlength="40" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="observations">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(resourcesElementName + '.object.observations')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.observations')">
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.observations"
                    maxlength="50" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          id="back-modal-button"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hideModal()">
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          id="back-modal-button"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          variant="primary">
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BTable,
  BFormTextarea,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BForm,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'

export default {
  components: {
    BTable,
    BFormTextarea,
    DatePicker,
    BFormCheckbox,
    BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    serviceBundleList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        {
          key: 'service.cups',
          label: this.$t('services.object.cups_cum'),
          sortable: true,
        },
        {
          key: 'service.cups_name',
          label: this.$t('arl_codes.object.services'),
          sortable: true,
        },
      ],
      resourcesElementName: 'arl_codes',
      customError: '',
      vigence_date_render: '',
      required,
    }
  },
  watch: {
    'elementData.authorization_date': function (newValue) {
      this.update_vigence_date()
    },
  },
  mounted() {
    this.update_vigence_date()
  },
  methods: {
    validateInput() {
      const regex = /^[0-9]+$/;
      if (!regex.test(this.elementData.arl_code)) {
        this.customError = 'El código solo puede contener números.';
      }
    },
    update_vigence_date() {
      if (this.elementData.authorization_date) {
        let service_vigency = 0
        if (
          this.elementData.service_bundle_group.bundle.id &&
          this.serviceBundleList &&
          this.serviceBundleList.length > 0
        ) {
          service_vigency = this.serviceBundleList.find(
            (element) =>
              element.id == this.elementData.service_bundle_group.bundle.id
          ).validity
        }
        let authorization_date = moment()
        if (this.elementData.authorization_date) {
          authorization_date = moment(
            this.elementData.authorization_date,
            'DD/MM/YYYY'
          )
        }
        this.elementData.vigence_date = authorization_date
          .add(service_vigency, 'days')
          .toDate()
        this.vigence_date_render = moment(this.elementData.vigence_date).format(
          'DD/MM/YYYY'
        )
      }
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-service-edit')
    },
    save() {
      const regex = /^[0-9]+$/;
      if (!regex.test(this.elementData.arl_code)) {
        this.elementData.arl_code = ''
      }
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          this.$root.$emit('bv::hide::modal', 'modal-service-edit')
        }
      })
    },
  },
}
</script>

<style></style>
