<template>
  <b-col cols="12">
    <b-card-header>
      <div class="text-primary h2">
        <span class="d-none d-sm-inline">{{
          $t(resourcesElementName + '.plural_name')
        }}</span>
      </div>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        v-if="elementData.bundles"
        id="refElementsListTable"
        ref="refElementsListTable"
        :empty-text="$t(resourcesElementName + '.search.not_found')"
        :fields="tableColumns"
        :items="elementData.bundles"
        class="position-relative"
        primary-key="id"
        responsive
        show-empty>
        <template #cell(authorization_date)="data">
          {{ formatDate(data.item, 'authorization_date') }}
        </template>
        <template #cell(vigence_date)="data">
          {{ formatDate(data.item, 'vigence_date') }}
        </template>
        <template #cell(services)="data">
          {{ getServices(data.item) }}
          <b-button
            :id="`see-services-${data.item.id}`"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="primary"
            @click="
              seeServices(data.item.services.map((service) => service.service))
            ">
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              :id="`edit-button-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              @click="editService(data.item)">
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- ? EDIT -->
            <b-tooltip
              :target="`edit-button-${data.item.id}`"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <div>
        <b-modal
          id="modal-service-detail"
          :title="$t(resourcesElementName + '.object.view_services')"
          hide-footer
          size="lg">
          <template>
            <service-detail-tab :element-data="currentGroupData" />
          </template>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-service-edit"
          :title="$t(resourcesElementName + '.object.edit')"
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          hide-footer
          size="xl">
          <template>
            <edit-service-tab
              :element-data="currentGroupData"
              :service-bundle-list="serviceBundleList" />
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-col>
</template>

<script>
import {
  BButton,
  BCardBody,
  BCardHeader,
  BCol,
  BModal,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import moment from 'moment'
import EditServiceTab from './EditService.vue'
import ServiceDetailTab from '../modal/ServiceDetail.vue'

export default {
  components: {
    ServiceDetailTab,
    BTooltip,
    BModal,
    BTable,
    BButton,
    BCol,
    BCardHeader,
    BCardBody,
    EditServiceTab,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    serviceBundleList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      resourcesElementName: '',
      required,
      isNew: false,
      currentGroupData: {},
    }
  },
  computed: {},
  beforeDestroy() {},
  mounted() {
    this.resourcesElementName = 'arl_codes'
    this.tableColumns = [
      {
        key: 'service_bundle_group.bundle.bundle_name.value',
        label: this.$t(`${this.resourcesElementName}.object.package`),
        sortable: true,
      },
      {
        key: 'service_bundle_group.name',
        label: this.$t(`${this.resourcesElementName}.object.group`),
        sortable: true,
      },
      {
        key: 'services',
        label: this.$t(`${this.resourcesElementName}.object.services`),
        sortable: true,
      },
      {
        key: 'authorization_date',
        label: this.$t(
          `${this.resourcesElementName}.object.authorization_date`
        ),
        sortable: true,
      },
      {
        key: 'vigence_date',
        label: this.$t(`${this.resourcesElementName}.object.vigence_date`),
        sortable: true,
      },
      {
        key: 'arl_code',
        label: this.$t(`${this.resourcesElementName}.object.arl_code`),
        sortable: true,
      },
      {
        key: 'authorizated_by',
        label: this.$t(`${this.resourcesElementName}.object.authorizated_by`),
        sortable: true,
      },
      {
        key: 'observations',
        label: this.$t(`${this.resourcesElementName}.object.observations`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.resourcesElementName}.object.actions`),
      },
    ]
  },
  methods: {
    seeServices(group) {
      this.currentGroupData = group
      this.$root.$emit('bv::show::modal', 'modal-service-detail')
    },
    getServices(element) {
      let ret = ''
      console.log(element)
      for (const service of element.services) {
        ret += `${service.service.cups || service.service.cum_file} `
      }
      return ret
    },
    editService(element) {
      this.currentGroupData = element
      this.isNew = false
      this.$root.$emit('bv::show::modal', 'modal-service-edit')
    },
    formatDate(element, field) {
      return element[field]
        ? moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
        : ''
    },
  },
}
</script>

<style></style>
